import React from 'react'
import { container } from '../styles/theme'
import styled from '@emotion/styled'
import FixedAspectBox from './fixed-aspect-box'

const videos = [
  {
    url: 'https://www.youtube.com/embed/O8wsPxZnYII',
    title:
      "Fox Tailoring's John Parrett on the Skill of Modern Tailoring — 2020 Shake & Stir Festival — Part 1",
  },
  {
    url: 'https://www.youtube.com/embed/poLpvhfPJxY',
    title:
      "Fox Tailoring's John Parrett on the Skill of Modern Tailoring — 2020 Shake & Stir Festival — Part 2",
  },
]

const ShakeStirVideos = () => {
  return (
    <Container>
      {videos.map(({ url, title }) => (
        <FixedAspectBox relativeWidth={16} relativeHeight={9} key={url}>
          <iframe
            title={title}
            src={`${url}?rel=0`}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </FixedAspectBox>
      ))}
    </Container>
  )
}

const Container = styled.div`
  ${container}

  >* {
    margin-top: 16px;
  }
`

export default ShakeStirVideos
