import React from 'react'
import Seo from '../components/SEO'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import evenbriteLogoSrc from '../img/eventbrite-logo.svg'

import Layout from '../components/layout'
import { colors, container, button } from '../styles/theme'
import PageBody from '../components/shared/page-body'
import { Link } from 'gatsby'

import GGVideo from '../components/gareth-gates-event-video'
import ShakeStirVideos from '../components/shake-stir-event-videos'

const EventsPage = () => (
  <Layout>
    <Seo title="Events" pathname="/events/" />
    <EventsPageBody>
      <TitleContainer>
        <h1 className="heading--h1">Fox Tailoring Events &amp; Appearances</h1>
      </TitleContainer>
      <Container>
        <Event>
          <StaticImage
            src="../img/miami-vice-event-feature.jpg"
            alt="Miami Vice and the Sound of the Eighties Event"
            layout="fullWidth"
          />
          <h3 className="heading heading--h2">
            <span style={{ fontSize: '1.4em' }}>Miami Vice</span> <br />
            <em>and the sound of the Eighties</em>
            <br />
            <span>Friday, 29th July 2022</span>
          </h3>
          <p>
            Enjoy the fantastic sounds, fashion and dancing from Miami Vice at
            the Nici hotel (formerly the Savoy) in Bournemouth.
          </p>

          <div
            style={{
              margin: '20px auto 0',
              padding: '10px 30px',
              border: '1px solid rgba(255,255,255,.1)',
              display: 'inline-block',
              background: 'rgba(255,255,255,.05)',
            }}
          >
            <p style={{ marginBottom: 12 }}>
              <Button
                as="a"
                href="https://www.eventbrite.co.uk/e/miami-vice-garden-party-tickets-360821285177"
              >
                Tickets now available!
                <br />
                <img
                  src={evenbriteLogoSrc}
                  alt="Eventbrite logo"
                  height={16}
                  style={{ marginBottom: '7px' }}
                />
              </Button>
              <br />
              <em>Early Bird discount for first 100!</em>
            </p>
          </div>

          <p>
            <em>Featuring</em> <strong>VIRAMUNDO</strong>
            <br />
            Experience the Carnival atmosphere of one of the most exciting
            Brazilian Latin Bands of the 80’s
          </p>
          <p>
            <em>Plus</em> <strong>LEA MARTIN</strong>
            <br />
            Bournemouth’s brightest Singer/Entertainer with performances
            eclipsing the songs of Ricky Martin, Enrique Inglesias, Gloria
            Estefan and many hit sounds from that era.
          </p>
          <p>
            <em>Plus</em> <strong>MAREK & GEORGIA</strong>
            <br />
            (M & G) displaying their exceptional talent as Latin Ballroom
            Performers.
          </p>
        </Event>
        <Event>
          <h3 className="heading heading--h2">
            Gala Dinner & James Bond Fashion Show
            <br />
            <span>2nd December 2021</span>
          </h3>
          <p style={{ marginBottom: 12 }}>
            A fabulous evening of entertainment and dining at the Marsham Court
            Hotel with performances from THE COLLECTIVE.
          </p>
          <StaticImage
            src="../img/gala-dinner-bond-header.jpg"
            alt="Gala Dinner and James Bond Fashion Show"
            layout="fullWidth"
          />
        </Event>
        <Event>
          <h3 className="heading heading--h2">
            2020 Shake &amp; Stir Festival
          </h3>
          <p style={{ marginBottom: 12 }}>
            Fox Tailoring was delighted to be part of the 2020 Shake &amp; Stir
            Festival.
          </p>
          <ShakeStirVideos />
        </Event>
        <Event>
          <Link to="/events/gareth-gates-march-2020/">
            <h3 className="heading heading--h2">
              An Intimate Evening with Gareth Gates
              <br />
              <span>12th March 2020</span>
            </h3>
            <p style={{ marginBottom: 12 }}>
              Thank you to everyone who attended our Intimate Evening with
              Gareth Gates!
              <br />
              We look forward to seeing you at future Fox Tailoring events.
            </p>
          </Link>
          <GGVideo />
        </Event>
      </Container>
    </EventsPageBody>
  </Layout>
)

const EventsPageBody = styled(PageBody)`
  padding: 60px 0;
  background: linear-gradient(${colors.black}, #000);
  background-size: cover;
  color: #fff;
  text-align: center;
`

const TitleContainer = styled.div`
  ${container};
  color: ${colors.white};
  text-shadow: 0 2px 4px ${colors.black}55;
  text-align: center;
`

const Container = styled.div`
  ${container};
  max-width: 840px;
`

const Event = styled.article`
  border: 1px solid ${colors.darkGrey};
  padding: 16px 24px;

  margin: 32px auto;
  a {
    text-decoration: none;
  }
`

const Button = styled.div`
  ${button.outline};
  display: inline-block;
  padding: 4px 16px;
  margin-bottom: 20px;
  font-size: 2.1rem;
  text-decoration: underline !important;
  text-decoration-color: rgba(255, 255, 255, 0.6) !important;

  &:hover {
    text-decoration-color: rgba(255, 255, 255, 1) !important;
  }
`
// const HeroImageWrapper = styled.div`
//   border: 2px solid ${colors.accent};
//   max-width: ${760 + 4}px;
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 32px;
// `

// const GarethGatesHero = () => {
//   const { hero } = useStaticQuery(graphql`
//     {
//       hero: file(relativePath: { eq: "img/gareth-gates-event-promo.jpg" }) {
//         childImageSharp {
//           fluid(maxWidth: 760, quality: 80) {
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }
//       }
//     }
//   `)

//   return (
//     <HeroImageWrapper>
//       <Image
//         {...hero.childImageSharp}
//         alt="12th March 2020 - An Intimate Evening with Gareth Gates - Bournemouth"
//         style={{
//           margin: '0 auto',
//         }}
//       />
//     </HeroImageWrapper>
//   )
// }

export default EventsPage
